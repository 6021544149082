<template>
  <v-dialog
    v-model="model"
    transition="scale-transition"
    :max-width="1200"
    v-bind="$attrs"
    v-on="$listeners"
  >
    <v-card
      class="elevation-3 rounded-lg d-flex flex-column"
      :max-width="1200"
    >
      <v-card-title>
        <span>
          {{ $t('messages.views.admin.components.regulation.smartway.scoresModal.title') }}
        </span>

        <v-spacer />

        <span>
          <v-btn
            icon
            @click="$emit('close')"
          >
            <v-icon>
              mdi-close
            </v-icon>
          </v-btn>
        </span>
      </v-card-title>

      <v-card-text>
        <v-simple-table>
          <template v-slot:default>
            <thead>
              <tr>
                <th>
                  {{ $t('messages.views.admin.components.regulation.smartway.scoresModal.algorithmName') }}
                </th>
                <th
                  v-for="number in trafficLightPlanNumbers"
                  :key="`traffic-light-plan-number-${number}`"
                  class="text-center"
                >
                  {{ $t('messages.views.admin.components.regulation.smartway.scoresModal.trafficLightPlanNumber', {number: number}) }}
                </th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(values, name) in computedScores"
                :key="`score-${name}`"
              >
                <td>
                  <v-icon class="mr-2">
                    {{ icons[name] }}
                  </v-icon>
                  {{ $t('constants.crossroad.suggestionScore.' + name) }}
                </td>
                <template v-if="values !== null">
                  <td
                    v-for="(score, i) in values"
                    :key="`score-${name}-${i}`"
                    class="text-center"
                    :class="{
                      warning: score.insufficientData,
                      'white--text': score.insufficientData,
                    }"
                  >
                    <v-icon
                      v-if="score.insufficientData"
                      dark
                    >
                      mdi-close
                    </v-icon>
                    <template v-else>
                      {{ Math.round(score.score * 100) + '%' }}
                    </template>
                  </td>
                </template>
                <template v-else>
                  <td
                    v-for="(trafficLightPlanNumber, i) in trafficLightPlanNumbers"
                    :key="`score-${name}-${i}`"
                    class="text-center grey"
                  >
                    <v-icon dark>
                      mdi-close
                    </v-icon>
                  </td>
                </template>
              </tr>
            </tbody>
          </template>
        </v-simple-table>

        <div class="text-right">
          <v-chip
            color="warning"
            class="ma-2"
          >
            {{ $t('messages.views.admin.components.regulation.smartway.scoresModal.insufficientData') }}
          </v-chip>

          <v-chip
            color="grey white--text"
            class="ma-2"
          >
            {{ $t('messages.views.admin.components.regulation.smartway.scoresModal.disabled') }}
          </v-chip>
        </div>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
  export default {
    props: {
      scores: {
        type: Object,
        required: true,
      },
    },

    data: function () {
      return {
        model: true,
        icons: {
          ATTENDANCE: 'mdi-motion-sensor',
          DURATION: 'mdi-timeline-clock-outline',
          TREND: 'mdi-chart-bell-curve',
        },
      };
    },

    computed: {
      trafficLightPlanNumbers () {
        const names = Object.keys(this.scores);
        const firstName = names[0];

        const trafficLightPlanNumbers = [];
        for (const type of Object.keys(this.scores)) {
          if (this.scores[type] !== null) {
            for (const score of this.scores[firstName]) {
              trafficLightPlanNumbers.push(score.trafficLightPlanNumber);
            }
            return trafficLightPlanNumbers.sort();
          }
        }

        return [];
      },
      computedScores () {
        const computedScores = {
          ATTENDANCE: null,
          DURATION: null,
          TREND: null,
        };

        for (const name of Object.keys(this.scores)) {
          if (this.scores[name] !== null) {
            computedScores[name] = [];

            for (const score of this.scores[name]) {
              computedScores[name][score.trafficLightPlanNumber] = score;
            }
          }
        }

        return computedScores;
      },
    },

    watch: {
      model: function (newValue, oldValue) {
        if (!newValue) {
          this.$emit('close');
        }
      },
    },
  };
</script>
